:root {
    --ios-px-gap: 120px;
}

body {
    margin: 0;
    font-family: nunitoRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    overflow: hidden;
}

* {
    box-sizing: border-box;
}

@font-face {
    font-family: nunitoExtraLight;
    font-weight: 200;
    font-style: normal;
    src: url('assets/fonts/nunito/Nunito-ExtraLight.ttf');
}

@font-face {
    font-family: nunitoLight;
    font-weight: 300;
    font-style: normal;
    src: url('assets/fonts/nunito/Nunito-Light.ttf');
}

@font-face {
    font-family: nunitoRegular;
    font-weight: 400;
    font-style: normal;
    src: url('assets/fonts/nunito/Nunito-Regular.ttf');
}

@font-face {
    font-family: NunitoSemiBold;
    font-weight: 600;
    font-style: oblique;
    src: url('assets/fonts/nunito/Nunito-SemiBold.ttf');
}

@font-face {
    font-family: nunitoBold;
    font-weight: 700;
    font-style: oblique;
    src: url('assets/fonts/nunito/Nunito-Bold.ttf');
}

.menuMobileList {
    list-style-type: none;
    margin: -8px 0px 0px 0px;
    padding: 0px 8px 0px 0px;
    overflow-y: auto;
}

.ios-styles .menuMobileList {
    max-height: calc(100vh - (146px + var(--ios-px-gap)));
}

.card {
    width: 100%;
    min-width: fit-content;
    max-width: calc(100% - 32px);
    margin: 0 auto;
    padding: 16px 0px 0px 0px;
    overflow-y: auto !important;
    border: none !important;
}

.card-forms-styles {
}

.card-border {
    border: 1px solid #e0e0e0 !important;
    border-radius: 15px !important;
}

.card-filters {
    width: 100%;
    min-width: fit-content;
    margin: 16px auto 0px auto;
    overflow-y: auto !important;
    border: none !important;
    border-radius: 4px;
    padding: 16px;
}

.newCard {
    width: 100%;
    margin: 0 auto;
    padding: 16px;
    margin-top: 96px;
    overflow-y: auto !important;
}

.card.home-card {
    padding: 0px !important;
    overflow-y: hidden !important;
    width: calc(100vw - 360px);
}

.home-card-img {
    width: 100%;
    object-fit: cover;
    margin-bottom: -6px;
}

@media (max-width: 959.95px) {
    .card.home-card {
        width: calc(100vw - 32px);
    }

    .home-card-img {
        object-fit: contain;
    }
}

/* .ios-styles .card {
  padding-bottom: 32px !important;
} */

.filter--closed {
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 5px;
    }
}

.filter--closed .card {
    margin-top: 32px;
}

.filter--open {
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 5px;
    }
}

.filter--open .card {
    margin-top: 32px;
}

.ios-styles .filter--open .card {
}

.card.card--form {
    padding: 10px;
    margin-top: 15px;
    overflow-y: auto;
}

.form-card {
    width: calc(100% + 25px);
    margin: 0 auto;
    padding: 5px 20px 20px 5px;
    margin-top: 10px;
    overflow-y: auto !important;
    scroll-margin-left: 5px;
    border: none !important;
}

.form-card .MuiGrid-container {
    max-width: 100vw;
}

.ios-styles .card.card--form {
    max-height: calc(100vh - (80px + var(--ios-px-gap)));
}

::-webkit-scrollbar {
    width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* ------- TEMPORARIO ------- */
/* MOVER PARA OVERRIDE DEPOIS */
.MuiDialog-paperWidthSm {
    max-width: 720px;
}

.MuiTableContainer-root {
    overflow-x: hidden !important;
}

.compact .MuiTableCell-root:not(:first-child, :last-child) {
    padding: 8px !important;
}

.compact th,
.compact td {
    font-size: 12px;
}

.MuiSelect-root,
.MuiTypography-body2,
.MuiListItem-root,
.MuiTextField,
input {
    font-family: nunitoRegular !important;
}

.temporary {
    width: 100%;
    margin: 16px 12px;
    font-family: NunitoSemiBold;
    text-transform: uppercase;
}

.chip-selected {
    background-color: #43b849 !important;
    color: #fff !important;
}

/* -------- UTILS -------- */
.mobile--visible {
    display: none !important;
}

@media (max-width: 959.95px) {
    .mobile--hidden {
        display: none !important;
    }

    .mobile--visible {
        display: inline-block !important;
    }

    .card {
        margin-top: 64px;
        max-height: calc(100vh - 80px) !important;
        max-width: calc(100vw - 16px);
    }

    .grid {
        max-width: 100vw;
    }

    .ios-styles .card {
        max-height: calc(100vh - (80px + var(--ios-px-gap))) !important;
    }

    .horizontal-scroll-mobile {
        overflow-x: auto;
        max-width: calc(100vw - 16px);
        margin-left: 8px;

    }

    .horizontal-scroll-mobile::-webkit-scrollbar {
        display: none;
    }

    .horizontal-scroll-mobile .card {
        /* width: 1000px; */
        width: 100%;
        min-width: max-content;
        max-width: unset;
    }

    .anti-card {
        margin-bottom: 32px !important;
    }
}

.no-scroll {
    overflow: hidden !important;
}

/* -------- TABLE -------- */

table th {
    font-family: nunitoBold !important;
    text-transform: uppercase;
}

table td {
    font-family: nunitoRegular !important;
}

.input-file {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input[type="file"] {
    appearance: none;
    border: 1px solid lightgrey;
    height: 57px;
    font-size: 16px;
    color: #333;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
}

input[type="file"]:hover {
    cursor: pointer;
}

input[type="file"]::before {
    content: url('./assets/images/icons/vectorIcon.png');
    display: inline-block;
    padding: 10px;
    border-radius: 5px;
    color: #333;
    cursor: pointer;
    z-index: 1;
}

input[type="file"]:focus::before {
    outline: none;
    box-shadow: none;
}

input[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
}

/* table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

table, td, th {
  border: none;
}

tr {
  border-bottom: 1px solid rgba(0,0,0,.12);
}

table th:first-child, table td:first-child {
  padding: 15px 5px 15px 25px;
}

table th {
  text-transform: uppercase;
  font-family: nunitoBold;
  font-size: 0.9rem;
}

th, td {
  padding: 16px;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px;
}

table tbody tr {
  transition: background-color .25s ease;
}

table tbody tr:hover {
  background-color: rgba(242,242,242,.5);
} */
